.po-tabs {
  li.po-tab {
    background-color: transparent;
    color: #525252;
    position: relative;
    cursor: pointer;
    &:after {
      content: "";
      width: 0;
      height: 4px;
      background: #419df1;
      position: absolute;
      left: 0;
      bottom: -5px;
      transition: 0.3s all ease-in;
    }
    &.react-tabs__tab--selected {
      color: #419df1;
      &:after {
        width: 100%;
      }
    }
  }
  .height-scroll{
    height: 100em;
  }
  .date-picker {
    & > span {
      padding-top: 0.25rem;
      padding-right: 0.75rem;
      padding-bottom: 0.25rem;
      padding-left: 0.75rem;
      height: auto;
      border-color: #d1d5da;
      border-radius: 6px;
      margin: 0;
    }
  }
}
.po-tabs {
  max-width: 1600px;
  margin: 0 auto;
}
.k-animation-container {
  background-color: white;
  border-radius: 5px;
}
.k-dropdownlist{
  visibility: hidden;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  &.in {
    height: auto;
  }
}

