@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
// @import "https://kendo.cdn.telerik.com/themes/5.0.1/material/material-main.css";

html,
body,
#root,
.App {
  height: 100%;
  font-family: "Poppins";
  overflow: hidden;
}
.side-bar,
.sticky {
  z-index: 40;
}
#headlessui-portal-root {
  z-index: 50;
  width: 100%;
  height: 100vh;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  position: fixed;
}
button {
  transition: 0.3s all ease-in;
}
#compo .k-input {
  border-color: rgba(209, 213, 219, 1) !important;
  border-width: 1px !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  padding-left: 10px !important;
  font-weight: 400 !important;
  background-color: white !important;
  font-family: "Poppins" !important;
  color: black !important;
  &:focus,
  &:focus-within {
    border-width: 2px !important;
    border-color: rgb(59, 130, 246) !important;
    box-shadow: none !important;
  }
}
#event .k-input {
  border-color: rgba(209, 213, 219, 1) !important;
  border-width: 1px !important;
  border-radius: 6px !important;
  font-size: 10px !important;
  padding-left: -5px !important;
  font-weight: 400 !important;
  background-color: white !important;
  font-family: "Poppins" !important;
  color: black !important;
  &:focus,
  &:focus-within {
    border-width: 2px !important;
    border-color: rgb(59, 130, 246) !important;
    box-shadow: none !important;
  }
}
#compo span {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  padding-right: 3px;
}
#event span {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  padding-right: 3px;
}
#compo .k-input-inner {
  padding: 0 !important;
  border: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  &:focus {
    border-width: 2px !important;
    border-color: rgb(59, 130, 246) !important;
    box-shadow: none !important;
  }
}
#poCompo .k-input {
  border-color: rgba(209, 213, 219, 1) !important;
  border-width: 1px !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  padding-left: 0px !important;
  font-weight: 400 !important;
  background-color: white !important;
  font-family: "Poppins" !important;
  color: #524f4f !important;
  &:focus,
  &:focus-within {
    border-width: 2px !important;
    border-color: rgb(59, 130, 246) !important;
    box-shadow: none !important;
  }
}
#JobStatusCompo .k-input {
  border-color: rgba(209, 213, 219, 1) !important;
  border-width: 1px !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  padding: 0px 10px !important;
  font-weight: 600 !important;
  background-color: rgb(59, 130, 246) !important;
  font-family: "Poppins" !important;
  color: white !important;
  cursor: none !important;
  &:focus,
  &:focus-within {
    border-width: 2px !important;
    border-color: rgb(59, 130, 246) !important;
    box-shadow: none !important;
  }
}
#JobStatusCompo .k-input-inner {
  padding-right: 0px !important;
  cursor: none !important;
}
#JobStatusCompo [type="text"]:focus {
  box-shadow: none !important;
  outline: none !important;
}
#poCompo span {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  padding-right: 3px;
}
#poDateCompo .k-input {
  // border-color: rgba(209, 213, 219, 1) !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  padding-left: 0px !important;
  padding-right: 10px;
  font-weight: 400 !important;
  background-color: white !important;
  font-family: "Poppins" !important;
  color: #524f4f !important;
  // &:focus,
  // &:focus-within {
  //   border-width: 2px !important;
  //   border-color: rgb(59, 130, 246) !important;
  //   box-shadow: none !important;
  // }
}
#eventDateCompo .k-input {
  // border-color: rgba(209, 213, 219, 1) !important;
  border-radius: 6px !important;
  font-size: 10px !important;
  padding-left: 0px !important;
  padding-right: 10px;
  font-weight: 400 !important;
  background-color: white !important;
  font-family: "Poppins" !important;
  color: black !important;
  // &:focus,
  // &:focus-within {
  //   border-width: 2px !important;
  //   border-color: rgb(59, 130, 246) !important;
  //   box-shadow: none !important;
  // }
}
#poDateCompo .k-input-inner {
  border: 0px !important;
  &:focus {
    border-width: 2px !important;
    border-color: rgb(59, 130, 246) !important;
    box-shadow: none !important;
  }
}
.k-grid-header thead > tr:not(:only-child) > th {
  font-size: 14px !important;
  line-height: 18px;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  color: #2563eb !important;
}
.k-detail-cell {
  padding: 0px !important;
}
.k-grid {
  font-size: 12px !important;
  border-style: none !important;
}
.k-filtercell {
  width: 100% !important;
}
.k-filter-row th {
  padding: 5px 2px !important;
}
.k-filtercell-operator {
  width: 0px;
}
.k-grid td {
  white-space: nowrap;
}
.k-detail-cell td,
.k-detail-cell th {
  padding: 15px 10px !important;
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected {
  background-color: #009cff2e;
}
.po .k-grid .k-grid-content-sticky,
.po .k-grid-header thead > tr:not(:only-child) > th.k-grid-header-sticky {
  border-color: rgba(0, 0, 0, 0.12) !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.job .k-grid .k-grid-content-sticky,
.job .k-grid-header thead th.k-grid-header-sticky {
  border-color: rgba(0, 0, 0, 0.12) !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
}
// .k-button-icon{
//   display: none !important;
// }

//For documents page
.documents {
  font-size: 0;
}
.product-name {
  color: black;
  border: #e5e7eb;
}
.k-button {
  height: 100%;
  padding: 0px !important;
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #71bce1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4f3696;
}
.slide-pane__content {
  padding: 0px !important;
  overflow-x: hidden;
  overflow-y: scroll;
}
.slide-pane__overlay {
  z-index: 100 !important;
}
.slide-pane__header {
  background: white;
}
#optionId {
  height: 34px !important;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.k-grid-header-wrap {
  border: none !important;
}
.k-sorted {
  height: 34px !important;
}
.k-filtercell {
  padding-left: 20px;
  padding-right: 10px;
}
.k-filtercell span {
  height: 32px !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(209, 213, 219, 1);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px !important;
  font-size: 10px !important;
  padding-left: 0px !important;
  font-weight: 400 !important;
  background-color: white !important;
  color: black !important;
}
.k-filtercell .k-input {
  height: 28px;
  background: transparent;
  border: none;
}
.k-filtercell .k-button span {
  border: none;
  height: 28px !important;
  width: 100%;
  background: transparent;
  border-radius: 0px 6px 6px 0px !important;
  box-shadow: none !important;
}
.k-filtercell .k-button {
  box-shadow: none;
  background: transparent;
  height: 28px;
}
.k-filtercell-operator {
  border-left: 1px solid rgba(209, 213, 219, 1);
}
.k-grid {
  border: none;
}
.k-cell-inner > .k-link {
  border: none;
}
@media print {
  body {
    height: auto;
  }
  .no-print,
  .layout {
    visibility: hidden;
  }
  .portrait {
    max-height: 800px !important;
    margin-top: 17px;
  }
  .prt {
    padding: 0px;
  }
  .port,
  .land {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    overflow-y: visible !important;
    padding-top: 25px !important;
    margin-bottom: 10px;
    border: none;
  }
  @page {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    margin: 33px 55px 33px 55px;
    // margin: 20px 10px;
  }
  td,
  input,
  textarea {
    page-break-inside: avoid;
  }
  footer {
    position: fixed !important;
    bottom: 5px;
  }
  .cargolabel {
    gap: 0px;
  }
  header {
    visibility: visible !important;
    display: inline !important;
    position: absolute;
    top: -5px;
    z-index: 70;
    right: 60px;
  }
}
.editable:focus {
  outline: none;
}
.land {
  // page-break-after: always;
  //overflow: visible;
  //break-inside: avoid;
  // height: 100%;
}
// .area{
//   height: 80px;
//   transition: height 0.2s ease-in-out;
// }
.znone {
  z-index: 0;
}
.stockMultiSelect .k-chip-list {
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-right: 20px !important;
}
.stockMultiSelect .k-chip {
  width: fit-content;
  min-width: max-content;
  height: 20px;
}
.stockMultiSelect .k-chip .k-chip-content {
  width: fit-content;
  min-width: max-content;
}
.stockMultiSelect .k-chip .k-chip-label {
  width: fit-content;
  overflow-x: visible;
  text-overflow: unset;
  font-size: small;
  font-weight: 500;
}
.select-quotation [type="text"]:focus {
  border-color: transparent !important;
  outline: none !important;
  box-shadow: none;
}

.OverlayBlack {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000099;
}
.k-grid-header-wrap .k-header:first-child {
  width: 50px;
  padding: 14px 10px !important;
  padding-left: 15px !important;
}

.ant-modal-footer .ant-btn-primary {
  background-color: #1677ff !important;
}

.OverlayWhite {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
}

.MultiSelectInlineSroll .k-chip-list {
  // display: flex !important;
  // gap: 5px !important;
  overflow-x: auto;
  // flex-direction: column;
  overflow-y: hidden;
  display: inline-flex;
  gap: 0 !important;
  align-items: flex-start;
}

.k-input-values {
  padding: 3px !important;
}

.MultiSelectInlineSroll .k-chip-list .k-input-inner {
  width: 0;
}

.k-chip-list .k-input-inner:focus {
  border-color: transparent !important;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.k-chip-list:focus {
  border: none;
}


.invalid-field {
  border: 2px solid red;
  background-color: rgba(255, 0, 0, 0.1);
}
.border-red-500 {
  border-color: #f56565;
}

.bg-red-50 {
  background-color: #fff5f5;
}


#poCompo .k-input.k-invalid:focus, 
#poCompo .k-input.k-invalid:focus-within {
    border-color: #ef4444 !important; /* Red border */
    border-width: 2px !important;
}